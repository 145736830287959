import React from 'react';
import { Link } from 'react-router-dom';
import './styles/CarCard.css';
import defaultCarImage from '../images/car.jpg';

const CarCard = ({ car }) => {
  const { year, id, make, model, price, mileage, thumbnail } = car;

  return (
    <Link to={`/inventory/${id}`} className="car-card-link">
      <div className="car-card">
        <img
          src={thumbnail || defaultCarImage} // Use pre-generated thumbnail or fallback
          alt={`${make} ${model}`}
          className="car-image"
          loading="lazy" // Improves performance by lazy loading images
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultCarImage; // Fallback image if URL fails
          }}
        />
        <div className="car-info">
          <h3>{`${year} ${make} ${model}`}</h3>
          <p><strong>Price:</strong> ${price.toLocaleString()}</p>
          <p><strong>Mileage:</strong> {mileage.toLocaleString()} mi</p>
        </div>
      </div>
    </Link>
  );
};

export default CarCard;
