// src/components/Contact/Contact.js

import React from 'react';
import './styles/Contact.css'; // Import the CSS file for styling

function Contact() {
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="contact-details">
        {/* Address Card */}
        <div className="contact-item">
          <h2>Address</h2>
          <p>
            711 S Vermont Ave <br />
            Ste 110B <br />
            Los Angeles, CA 90005<br />
          </p>
        </div>

        {/* Phone Number Card */}
        <div className="contact-item">
          <h2>Contact</h2>
          <p>
            +1 (213) 644-5320<br />
          </p>
        </div>

        {/* Email Address Card */}
        <div className="contact-item">
          <h2>Email</h2>
          <p>
            <a href="mailto:info@yourcompany.com">kstradeinc7788@gmail.com</a><br />
          </p>
        </div>
      </div>

      {/* Optional: Contact Form */}
      {/* Uncomment the following block if you want to include a contact form */}
      {/*
      <div className="contact-form">
        <h2>Send Us a Message</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>

          <button type="submit">Send Message</button>
        </form>
      </div>
      */}
    </div>
  );
}

export default Contact;
