import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore methods
import { db } from '../firebase/config'; // Import your Firebase configuration
import './styles/ApplyFinancing.css'; // Ensure this file exists and is correctly styled
import { Timestamp } from 'firebase/firestore';

function ApplyFinancing() {
  const [formData, setFormData] = useState({
    desiredLoanAmount: '', // New field for Desired Loan Amount
    desiredMonthlyPayment: '', // New field for Desired Monthly Payment
    lastName: '',
    firstName: '',
    birthDate: '',
    cellPhone: '',
    email: '', // Email field
    driverLicenseNumber: '',
    driverLicenseState: '',
    driverLicenseExpiration: '',
    socialSecurityNumber: '',
    address: '',
    howLongAtAddressYears: '',
    howLongAtAddressMonths: '',
    employerName: '',
    employerAddress: '',
    employerNumber: '',
    occupationOrRank: '',
    howLongAtEmployerYears: '',
    howLongAtEmployerMonths: '',
    monthlyIncome: '',
    incomeMethod: '',
    downPayment: '', // New field for down payment
    residenceType: '', // New field for Residence Type
    mortgageOrRent: '', // New field for Mortgage/Rent Payment
    paymentFrequency: '', // New field for payment frequency
    accountsInName: [], // New field for accounts in your name
    vehicleMake: '',
    vehicleModel: '',
    vehicleYear: '',
    vehicleMileage: '',
    interestedInTradeIn: 'No', // New field for trade-in interest
    tradeInMake: '', // New trade-in details fields
    tradeInModel: '',
    tradeInYear: '',
    tradeInMileage: '',
    additionalComments: '',
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => {
      const updatedAccounts = checked
        ? [...prevState.accountsInName, name]
        : prevState.accountsInName.filter((account) => account !== name);
      return { ...prevState, accountsInName: updatedAccounts };
    });
  };

  const [message, setMessage] = useState('');
  const [consent, setConsent] = useState('');

  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]})-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const formatSSN = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'cellPhone' || name === 'employerNumber') {
      setFormData({ ...formData, [name]: formatPhoneNumber(value) });
    } else if (name === 'socialSecurityNumber') {
      setFormData({ ...formData, [name]: formatSSN(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (consent !== 'Yes') {
      setMessage('You must provide your consent to proceed.');
      return;
    }
    try {
      const applicationsRef = collection(db, 'financing_applications');

      await addDoc(applicationsRef, {
        ...formData,
        submittedAt: Timestamp.now(),
      });

      setMessage('Thank you for your application! We will reach out to you as soon as possible :)');
      setFormData({
        desiredLoanAmount: '', // New field for Desired Loan Amount
        desiredMonthlyPayment: '', // New field for Desired Monthly Payment
        lastName: '',
        firstName: '',
        birthDate: '',
        cellPhone: '',
        email: '',
        driverLicenseNumber: '',
        driverLicenseState: '',
        driverLicenseExpiration: '',
        socialSecurityNumber: '',
        address: '',
        priorAddress: '', // New field for prior address
        accountsInName: [], // New field for accounts in your name
        driverLicenseState: '', // New field for Driver License State
        driverLicenseExpiration: '', // New field for Driver License Expiration
        howLongAtAddressYears: '',
        howLongAtAddressMonths: '',
        employerName: '',
        employerAddress: '',
        employerNumber: '',
        occupationOrRank: '',
        howLongAtEmployerYears: '',
        howLongAtEmployerMonths: '',
        monthlyIncome: '',
        incomeMethod: '',
        downPayment: '', // Reset down payment field
        residenceType: '', // New field for Residence Type
        mortgageOrRent: '', // New field for Mortgage/Rent Payment
        paymentFrequency: '', // New field for payment frequency
        accountsInName: [], // New field for accounts in your name
        vehicleMake: '',
        vehicleModel: '',
        vehicleYear: '',
        vehicleMileage: '',
        interestedInTradeIn: 'No', // New field for trade-in interest
        tradeInMake: '', // New trade-in details fields
        tradeInModel: '',
        tradeInYear: '',
        tradeInMileage: '',
        additionalComments: '',

      });
      setConsent('');
    } catch (error) {
      console.error('Error submitting application:', error);
      setMessage('There was an error submitting your application.');
    }
  };

  const renderMonthOptions = () => {
    return Array.from({ length: 12 }, (_, i) => (
      <option key={i} value={i}>
        {i} months
      </option>
    ));
  };

  return (
    <div className="apply-financing">
      <h2>Apply for Financing</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        {/* Loan Details Section */}
        <div className="section-header">Loan Information (Optional)</div>
        <div className="line"></div>

        <label>Desired Loan Amount (Optional):</label>
        <input
          type="number"
          name="desiredLoanAmount"
          value={formData.desiredLoanAmount}
          onChange={handleChange}
          placeholder="$"
        />

        <label>Desired Monthly Payment (Optional):</label>
        <input
          type="number"
          name="desiredMonthlyPayment"
          value={formData.desiredMonthlyPayment}
          onChange={handleChange}
          placeholder="$"
        />

        <div className="section-header">Personal Information</div>
        <div className="line"></div>  


        <div className="responsive-row-name">
          <div className="responsive-input">
            <label>First Name:</label>
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
          </div>
          <div className="responsive-input">
            <label>Last Name:</label>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
          </div>
        </div>

        <label>Birthdate:</label>
        <input type="date" name="birthDate" value={formData.birthDate} onChange={handleChange} required />

        <label>Cell Phone:</label>
        <input type="text" name="cellPhone" value={formData.cellPhone} onChange={handleChange} required />

        <label>Email:</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />

        <label>Driver License Number:</label>
        <input type="text" name="driverLicenseNumber" value={formData.driverLicenseNumber} onChange={handleChange} required />

        <label>Driver's License State:</label>
        <input type="text" name="driverLicenseState" value={formData.driverLicenseState} onChange={handleChange} placeholder="e.g., CA, NY" />

        <label>Driver's License Expiration Date:</label>
        <input type="date" name="driverLicenseExpiration" value={formData.driverLicenseExpiration} onChange={handleChange} />

        <label>Social Security Number:</label>
        <input type="text" name="socialSecurityNumber" value={formData.socialSecurityNumber} onChange={handleChange} required />

        <label>Address:</label>
        <input type="text" name="address" value={formData.address} onChange={handleChange} required />

        <label>How long have you been residing at the current address? :</label>
        <div className="responsive-row-long">
          <div className="responsive-input">
            <label>Years:</label>
            <input type="text" name="howLongAtAddressYears" value={formData.howLongAtAddressYears} onChange={handleChange} />
          </div>
          <div className="responsive-input">
            <label>Months:</label>
            <select name="howLongAtAddressMonths" value={formData.howLongAtAddressMonths} onChange={handleChange} required>
              <option value="">Select</option>
              {renderMonthOptions()}
            </select>
          </div>
        </div>
        
        <label>If you have lived at your current residence less than 5 years, please enter your prior address:</label>
        <input name="priorAddress" value={formData.priorAddress} onChange={handleChange} placeholder="Enter your prior address" />


        <label>Residence Type:</label>
        <select
          name="residenceType"
          value={formData.residenceType}
          onChange={handleChange}
          required
        >
          <option value="">Select Residence Type</option>
          <option value="own">Own</option>
          <option value="rent">Rent</option>
          <option value="livingWithFamily">Living with Family</option>
          <option value="other">Other</option>
        </select>

        <label>Mortgage/Rent Payment:</label>
        <input
          type="number"
          name="mortgageOrRent"
          placeholder="$"
          value={formData.mortgageOrRent}
          onChange={handleChange}
          required
        />

        <label>Which bills are registered under your name? :</label>
<div>
  <label>
    <input
      type="checkbox"
      className="large-checkbox"
      name="Utilities"
      onChange={handleCheckboxChange}
      checked={formData.accountsInName?.includes('Utilities')}
    />
    Utilities
  </label>
  <label>
    <input
      type="checkbox"
      className="large-checkbox"
      name="Savings"
      onChange={handleCheckboxChange}
      checked={formData.accountsInName?.includes('Savings')}
    />
    Savings
  </label>
  <label>
    <input
      type="checkbox"
      className="large-checkbox"
      name="Checking"
      onChange={handleCheckboxChange}
      checked={formData.accountsInName?.includes('Checking')}
    />
    Checking
  </label>
  <label>
    <input
      type="checkbox"
      className="large-checkbox"
      name="Phone"
      onChange={handleCheckboxChange}
      checked={formData.accountsInName?.includes('Phone')}
    />
    Phone
  </label>
</div>


        <div className="section-header">Employment Information</div>
        <div className="line"></div>

        <label>Employer Name:</label>
        <input type="text" name="employerName" value={formData.employerName} onChange={handleChange} required />

        <label>Employer Address:</label>
        <input type="text" name="employerAddress" value={formData.employerAddress} onChange={handleChange} required />

        <label>Employer Number:</label>
        <input type="text" name="employerNumber" value={formData.employerNumber} onChange={handleChange} required />

        <label>Occupation or Rank:</label>
        <input type="text" name="occupationOrRank" value={formData.occupationOrRank} onChange={handleChange} required />

        <label>How long have you been working at the current employer? :</label>
          <div className="responsive-row-long">
            <div className="responsive-input">
              <label>Years:</label>
              <input
                type="text"
                name="howLongAtEmployerYears"
                value={formData.howLongAtEmployerYears}
                onChange={handleChange}
                required
              />
            </div>
            <div className="responsive-input">
              <label>Months:</label>
              <select
                name="howLongAtEmployerMonths"
                value={formData.howLongAtEmployerMonths}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                {renderMonthOptions()}
              </select>
            </div>
          </div>

        <div className="section-header">Financial Information</div>
        <div className="line"></div>

        <label>Monthly Income:</label>
        <input type="number" name="monthlyIncome" placeholder="$" value={formData.monthlyIncome} onChange={handleChange} required />

        <label>Income Method:</label>
        <select name="incomeMethod" value={formData.incomeMethod} onChange={handleChange} required>
          <option value="">Select Method</option>
          <option value="cash">Cash</option>
          <option value="check">Check</option>
          <option value="directDeposit">Direct Deposit</option>
        </select>

        <label>How often are you paid?</label>
        <select name="paymentFrequency" value={formData.paymentFrequency} onChange={handleChange}>
          <option value="">Select</option>
          <option value="weekly">Weekly</option>
          <option value="biweekly">Bi-Weekly</option>
          <option value="semimonthly">Semi-Monthly</option>
          <option value="monthly">Monthly</option>
        </select>

        <label>How much can you pay for the down payment?</label>
        <input
          type="number"
          name="downPayment"
          placeholder="$"
          value={formData.downPayment}
          onChange={handleChange}
          required
        />

<div className="section-header">Vehicle Information (Optional)</div>
<label className='subtitle'>Do you have a specific car in mind? (Optional)</label>
<select
  name="specificCarInMind"
  value={formData.specificCarInMind}
  onChange={handleChange}
>
  <option value="No">No</option>
  <option value="Yes">Yes</option>
</select>

{formData.specificCarInMind === 'Yes' && (
  <>
    <label>Vehicle Make:</label>
    <input
      type="text"
      name="vehicleMake"
      value={formData.vehicleMake}
      onChange={handleChange}
      placeholder="e.g., Toyota, Ford"
    />

    <label>Vehicle Model:</label>
    <input
      type="text"
      name="vehicleModel"
      value={formData.vehicleModel}
      onChange={handleChange}
      placeholder="e.g., Camry, F-150"
    />

    <label>Vehicle Year:</label>
    <input
      type="number"
      name="vehicleYear"
      value={formData.vehicleYear}
      onChange={handleChange}
      placeholder="e.g., 2020"
    />

    <label>Vehicle Mileage:</label>
    <input
      type="number"
      name="vehicleMileage"
      value={formData.vehicleMileage}
      onChange={handleChange}
      placeholder="e.g., 50000"
    />
  </>
)}

        
        <div className="section-header">Trade-In Details (Optional)</div>
        <div className="line"></div>
        <label className='subtitle'>Are you interested in a Trade-In? (Optional)</label>
        <select
          name="interestedInTradeIn"
          value={formData.interestedInTradeIn}
          onChange={handleChange}
        >
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>

        {formData.interestedInTradeIn === 'Yes' && (
          <>
            <label>Trade-in Make:</label>
            <input
              type="text"
              name="tradeInMake"
              value={formData.tradeInMake}
              onChange={handleChange}
              placeholder="e.g., Toyota"
            />

            <label>Trade-in Model:</label>
            <input
              type="text"
              name="tradeInModel"
              value={formData.tradeInModel}
              onChange={handleChange}
              placeholder="e.g., Camry"
            />

            <label>Trade-in Year:</label>
            <input
              type="number"
              name="tradeInYear"
              value={formData.tradeInYear}
              onChange={handleChange}
              placeholder="e.g., 2018"
            />

            <label>Trade-in Mileage:</label>
            <input
              type="number"
              name="tradeInMileage"
              value={formData.tradeInMileage}
              onChange={handleChange}
              placeholder="e.g., 50000"
            />

            
          </>
        )}


      <div className="acknowledgement">
          <h3>Acknowledgement and Consent</h3>
          <p>
            By submitting this application, you certify that all the statements and information provided in this
            application are true, accurate, and complete, and are made for the purpose of obtaining credit. You further
            authorize us to obtain information from your personal credit profile as part of the prequalification process.
          </p>
          <p>
            You acknowledge that this form, which you have filled out on our website, is not a loan application but a
            request to obtain prequalification. Furthermore, you confirm your understanding that any pre-approval or
            prequalification is subject to a detailed review and acceptance of your credit information.
          </p>
        </div>

        <label>Do you consent to the terms above?</label>
        <select name="consent" value={consent} onChange={(e) => setConsent(e.target.value)} required>
          <option value="">Select</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>

        <button type="submit">Submit Application</button>
      </form>
    </div>
  );
}

export default ApplyFinancing;

